import _ from 'lodash'
import { app } from '@src/models'
import { IPage, IPower, IAuth, IOnlinePower, powerType } from '@src/models/global'
import { cloneObject, toJson } from './util'
import { getCryptoJS } from './crypto'
import crypto from 'crypto'
import moment from 'moment'
import NotFound from '@src/layout/not_found'
import { modules } from '@src/libs/load-services'
import { filterAuthItems } from '@src/config'
import OSS from 'ali-oss'
import { v1 as uuidv3}  from 'uuid'

export const materialKeys = ['quantityCount', 'boxCount', 'boardCount', 'grossWeight', 'volume']

let _CryptoJS: any = null
if (!(window as any)._CryptoJS) {
    _CryptoJS = getCryptoJS();
} else {
    _CryptoJS = (window as any)._CryptoJS;
}
// console.log('cryptojs', getCryptoJS())

export const sha1hash = (content: any) => {
    const hash = crypto.createHash('sha1')
    hash.update(content)
    return hash.digest('hex')
}

export const md5hash = (str: string) => {
    const md5sum = crypto.createHash('md5')
    md5sum.update(str)
    str = md5sum.digest('hex')
    return str
}

/**
 * @description 地址选择值转需要的json格式
 * @param {any[]} addrItems 地址组件选择值opts
 * @param {string} extra 详细地址
 */
export const addrItemsToAddress = (addrItems: any, extra = '') => {
    const rt: any = {}
    try {
        rt.pro = addrItems[0] ? addrItems[0].label : ''
        rt.city = addrItems[1] ? addrItems[1].label : ''
        rt.dist = addrItems[2] ? addrItems[2].label : ''
        rt.street = addrItems[3] ? addrItems[3].label : ''
        rt.extra = extra || ''
        rt.vals = addrItems.length ? addrItems.map((item: any) => item.value) : []
        const str = addrItems && addrItems.length ? addrItems.map((item: any) => item.label).join('/') : ''
        rt.formatAddress = str ? (rt.extra ? `${str} ${rt.extra}` : str) : rt.extra
        // console.log(1, rt)
        return rt
    } catch (error) {
        console.log('addrItemsToAddress', error)
        return {}
    }
}

/**
 * @description 合并地址和详细地址
 * @param {*} address 地址
 * @param {*} extra 详细地址
 */
export const concatAddrAndDetails = (address: any, extra: any) => {
    try {
        const json = toJson(address) || {}
        json.extra = extra
        const arr = json.formatAddress.split(' ')
        if (arr.length > 1) {
            json.formatAddress = arr[0]
        }
        json.formatAddress += json.extra ? ` ${json.extra}` : ''
        // console.log(2, json)
        return json
    } catch (error) {
        return toJson(address)
    }
}

/**
 * @description 获取addrJson数据val
 * @param {*} addrJson 
 */
export const getAddrJsonVal = (addrJson = {}, key: any) => {
    try {
        const json = typeof addrJson === 'string' ? JSON.parse(addrJson) :  typeof addrJson === 'object' ? addrJson : { vals: [], formatAddress: '' }
        let rt = json[key]
        if (key === 'vals') {
            rt = rt || []
        } else {
            rt = rt || ''
        }
        // console.log(3, rt)
        return rt
    } catch (error) {
        console.log('getAddrJsonVal', error)
        return key === 'vals' ? [] : ''
    }
}

export const encryption = (params: any) => {
    const {
        data,
        type,
        param,
    } = params
    let {
        key
    } = params
    const result = JSON.parse(JSON.stringify(data))
    if (type === 'Base64') {
        param.forEach((ele: any) => {
            result[ele] = btoa(result[ele])
        })
    } else {
        param.forEach((ele: any) => {
            const target = result[ele]
            key = _CryptoJS.enc.Latin1.parse(key)
            // 加密
            const encrypted = _CryptoJS.AES.encrypt(
                target,
                key, {
                    iv: key,
                    mode: _CryptoJS.mode.CBC,
                    padding: _CryptoJS.pad.ZeroPadding
                })
            result[ele] = encrypted.toString()
        })
    }
    return result
}

export const formatTime = (t: any, fmt: string | undefined = 'YYYY-MM-DD HH:mm') => {
    const str = t ? moment(t).format(fmt) : ''
    return str
}

export const authSort = (list: IPower[]): IPower[] => {
    return list.sort((curr: any, next: any) => {
        const currSort = curr.sort || (curr.main && curr.main.sort)
        const nextSort = next.sort || (next.main && next.main.sort)
        return (currSort && nextSort) ? currSort - nextSort : 0
    })
}

export const treeSort = (tree: IPower[]): IPower[] => {
    return tree.sort((curr: IPower, next: IPower) => {
        const currSort = curr.sort
        const nextSort = next.sort
        return (currSort && nextSort) ? currSort - nextSort : 0
    }).map((item: IPower) => {
        if (item.children && item.children.length) {
            item.children = treeSort(item.children)
        }
        return item
    })
}

export const pageConvert = (list: IPage[]): IPower[] => {
    return list.map((item: IPage) => ({
        ...(item.main),
        children: item.children || []
    }))
}

export const authJoin = (pList: IPower[], cList: IPower[]) => {
    cList = cloneObject(cList)
    return pList.map((parent: IPower) => {
        const targetList: IPower[] = []
        for (let i = 0; i < cList.length; i++) {
            const c = cList[i]
            if (c.parent === parent.id) {
                targetList.push(cList.splice(i, 1)[0])
                i--
            }
        }
        if (!parent.children || parent.children.length < 1) {
            parent.children = targetList
        }
        return parent
    })
}

export const authToTree = (authPage: IAuth<IPage>): IPower[] => {
    const list = _.values(authPage).filter(item => (item.main && !filterAuthItems.some(id => item.main.id === id)))
    // console.log('l', authPage, list)
    const parentList = pageConvert(list.filter(item => !item.main.parent))
    const progenyList = pageConvert(list.filter(item => !!item.main.parent))
    const ret = treeSort(authJoin(parentList, progenyList))
    // console.log('authToTree', list, parentList, progenyList, ret)
    return ret
}

export const treeToList = (tree: IPower[]): IPower[] => {
    tree = cloneObject(tree)
    return tree.reduce((ret: IPower[], cur: IPower) => {
        if (cur.children && cur.children.length) {
            cur.children = treeToList(cur.children)
        }
        return ret.concat([{ ...cur, children: [] }, ...(cur.children || [])])
    }, [])
}

export const onlineTreeConvert = (list: IOnlinePower[], localList: IPower[]): IPower[] => {
    localList = cloneObject(localList)
    // console.log('onlineTreeConvert', list, localList)
    // 先 filter 过滤掉本地不存在的权限之后再 map 做处理
    return list.filter((item: any) => localList.some((lc) => lc.id === item.code)).map((item: any) => {
        let target = null
        for (let i = 0; i < localList.length; i++) {
            const lc = localList[i]
            if (item.code === lc.id) {
                target = localList.splice(i, 1)[0]
                item = {
                    ...target,
                    children: item.children || []
                }
                break
            }
        }
        if (item.children && item.children.length) {
            item.children = onlineTreeConvert(item.children, localList)
        }
        return item
    })
}

export const checkAndFilterOnlinePower = (localList: IPower[], onlineList: IPower[]): IPower[] => {
    // console.log('checkAndFilterOnlinePower', localList, onlineList)
    // 检查提示并过滤掉本地不存在的权限
    const ret = onlineList.filter((ol: IPower) => {
        if (!_.some(localList, (lc: IPower) => lc.id === ol.id)) {
            console.error('本地权限不存在请删除>>>>>>>', ol)
            return false
        } else {
            return true
        }
    })
    return ret
}

export const getAllModules = (onlineAuthList: IPower[]) => {
    const hasPermission = (list: IPower[],main: IPower) => {
        if (main.id === null) {
            return true
        } else if (list && !!main.id) {
            return !!list[(main as any).id]
        }
        return true
    }
    return _.values(modules).filter(item => item.id !== 'Login' && hasPermission(onlineAuthList, item.id))
}

export const getCurrentRouter = (path: string) => {
    const { localAuthList } = (app as any)._store.getState().global
    const target = _.find(localAuthList, ((item: IPower) => item.id === path)) || null
    return target
}

export const sortMenus = (list: IPower[]): IPower[] => {
    list.sort((curr, next) => ((curr.sort || 0) - (next.sort || 0)))
    return list
}

/* 下载文件 */
export const downloadFile = async (blob: any, fileName = 'download.xlsx') => {
    const doma = document.createElement("a")
    doma.download = fileName
    doma.href = URL.createObjectURL(blob) // 绑定a标签
    doma.click() // 模拟点击实现下载
    await setTimeout(() => { // 延时释放
        URL.revokeObjectURL(blob) // 用URL.revokeObjectURL()来释放这个object URL
    }, 100)
}

/* 
接口导出数据到EXCEL
*/
export const exportResToExcel = async (res: any) => {
    const header = res.headers
    try {
        // console.log('exp', res, header)
        if (!header['content-disposition']) {
            throw Error('end')
        }
        let contentDsposition = header['content-disposition']
        contentDsposition = contentDsposition.split(';')[1]
        const fileName = window.decodeURIComponent(contentDsposition.replace('filename=', ''))
        const blob = new Blob([res.data])
        await downloadFile(blob, fileName)
    } catch(e) {
        console.log(e)
        throw Error(e)
    }
}

// 获取日期段选择组件format格式化字符串,结束日期+1天
export const getRangeTime = (rangeTime: any[], formatter = 'YYYY-MM-DD HH:mm', endDatePlus = true) => {
    if (rangeTime && rangeTime.length > 1) {
        return {
            start: rangeTime[0] ? moment(rangeTime[0]).format(formatter) : null,
            end: rangeTime[1] ? (endDatePlus ? moment(rangeTime[1]).add(1, 'days').format(formatter) : moment(rangeTime[1]).format(formatter)) : null
        }
    } else {
        return {
            start: null,
            end: null
        }
    }
}

export const getCurrentModule = (theModules: IPower[], activeTab: powerType): (IPower | null) => {
    const ret = _.find(theModules, (m) => m.id === activeTab.id) || null
    return ret
}

export const tabsListToModuleList = (theModules: IPower[], tabsList: IPower[]): IPower[] => {
    const ret = _.map(tabsList, tab => {
        const target = _.find(theModules, m => m.id === tab.id || m.path === tab.path)
        // console.log('target', target)
        if (target) {
            return {
                ...target,
                id: tab.id
            }
        } else {
            return {
                ...tab,
                Component: NotFound
            }
        }
    })
    // console.log('ret', ret)
    return ret
}

export const compareObjKeys = (obj1: any, obj2: any, keys: string[]): boolean => {
    const flag = keys.some(key => {
        return obj1[key] !== obj2[key]
    })
    if (flag) {
        return true
    }
    return false
}

export const imgClient = () =>{ // 阿里云配置
    return new OSS({
        region: 'oss-cn-shenzhen',// 你的oss地址 ，具体位置见下图
        accessKeyId: 'LTAI4FchNbSskRzqxZXUgFZb',// 你的ak
        accessKeySecret: 'aOS1HBRhWD1Ns78cVnyBU1N7f2s4mF',// 你的secret
        // stsToken: '<Your securityToken(STS)>',//这里我暂时没用，注销掉
        bucket: 'frdscm',// 你的oss名字
        // imageHost: 'oss-cn-shenzhen.aliyuncs.com'
    })
}

export const randomString = ():string => { // 生成uuid
    const reg = /-/g;
    return uuidv3().replace(reg, '')
}

export const getSuffix = (filename: string) => { // 截取文件后缀名a.png => png
    const pos = filename.lastIndexOf('.')
    let suffix = ''
    if (pos !== -1) {
        suffix = filename.substring(pos)
    }
    return suffix
}