import React from 'react'
import { Layout } from 'antd'
import { connect } from 'dva'
import { isFullscreen } from '@src/libs/util'
import {
    tabsListToModuleList,
    compareObjKeys
} from '@src/libs/logic'
import NotFound from './not_found'
import OrderSearchPage from '@src/pages/monitor/transport_order_search/index.module'

const { Content } = Layout

const ContentModule = connect(({ global: {
    activeTab,
    moduleRefresh,
    pageDatas
} }) => {
    return {
        activeTab,
        moduleRefresh,
        pageDatas
    }
})(class ModuleItem extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        const { activeTab } = nextProps
        return this.props.activeTab.id !== activeTab.id
    }

    render() {
        // console.log('fn render')
        const { m, activeTab, moduleRefresh, pageDatas } = this.props
        const page = m.Component || NotFound
        // console.log('activeTab', m.id, pageDatas[m.id])
        return (
            <div
                className={`content_module ${(m.id === activeTab.id || m.id === 'notFound') ? 'active' : ''}`}
            // style={{ display: !(m.id === activeTab.id || m.path === activeTab.path) ? 'none' : 'block' }}
            >
                {
                    moduleRefresh ? null : page({ pageData: pageDatas[m.id], active: m.id === activeTab.id || m.path === activeTab.path })
                }
            </div>
        )
    }

})

function CustomContentModule({ m, activeTab, moduleRefresh }) {
    const page = m.Component || NotFound
    return (
        <div
            className={`content_module ${(m.id === activeTab.id || m.path === activeTab.path || m.id === 'notFound') ? 'active' : ''}`}
        >
            {
                moduleRefresh ? null : page()
            }
        </div>
    )
}

@connect(({ global: {
    activeTab,
    isFullScreening,
    allModules,
    tabsList,
    LOGIN_TYPE
} }) => {
    return {
        activeTab,
        isFullScreening,
        allModules,
        tabsList,
        LOGIN_TYPE
    }
})
class MyContent extends React.Component {

    contentDom = null

    shouldComponentUpdate(nextProps) {
        const keys = ['activeTab']
        return compareObjKeys(this.props, nextProps, keys)
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        // console.log(11, window.innerHeight)
        const { isFullScreening, dispatch } = this.props
        if (!isFullscreen() && isFullScreening) { // 全屏情况下按ESC键触发，更新isFullScreening状态
            dispatch({
                type: 'global/exitFullscreen'
            })
        }
        dispatch({
            type: 'global/setGlobalState',
            payload: {
                conHeight: window.innerHeight - 50
            }
        })
    }

    render() {
        const {
            allModules,
            tabsList,
            LOGIN_TYPE
        } = this.props
        const moduleList = tabsListToModuleList(allModules, tabsList)
        // console.log('Content render', moduleList)
        return (
            <Layout style={{ padding: '0', width: LOGIN_TYPE === 1 ? 'calc(100% - 260px)' : '100%' }}>
                {
                    LOGIN_TYPE === 1 ? (
                        <Content
                            id='content_fullscreen'
                            className='content_fullscreen'
                            ref={v => this.contentDom = v}
                        >
                            {
                                (moduleList && moduleList.length > 0) ? moduleList.map((m, mIndex) => {
                                    return (
                                        <ContentModule key={mIndex} m={m} />
                                    )
                                }) : null
                            }
                        </Content>
                    ) : (
                            <Content
                                id='content_fullscreen'
                                className='content_fullscreen'
                                ref={v => this.contentDom = v}
                            >
                                <CustomContentModule
                                    m={{
                                        id: 'OrderSearchPage',
                                        Component: OrderSearchPage
                                    }}
                                    activeTab={{
                                        id: 'OrderSearchPage'
                                    }}
                                    moduleRefresh={false}
                                />
                            </Content>
                        )
                }
            </Layout>
        )
    }
}

export default MyContent
