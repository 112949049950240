import React from 'react'
import { Layout } from 'antd'
import { connect } from 'dva'
import AsideMenu from './aside_menu'
import { isFullscreen } from '@src/libs/util'
import MyHeader from './header'
import MyContent from './content'

export interface IProps {
    dispatch: any;
    isFullScreening: boolean;
}


class CustomLayout extends React.Component<IProps, any> {
    public shouldComponentUpdate(nextProps: any) {
        // const keys = ['isFullScreening']
        return false
    }

    public componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    public handleResize = () => {
        const { isFullScreening, dispatch } = this.props
        if (!isFullscreen() && isFullScreening) { // 全屏情况下按ESC键触发，更新isFullScreening状态
            dispatch({
                type: 'global/exitFullscreen'
            })
        }
        dispatch({
            type: 'global/setGlobalState',
            payload: {
                conHeight: window.innerHeight - 50
            }
        })
    }

    public render() {
        // console.log('CustomLayout render', this.props)
        return (
            <Layout className='custom_layout'>
                <MyHeader />

                <Layout
                    className='main_content'
                >
                    <AsideMenu
                        title={false}
                        {...this.props}
                    />

                    <MyContent />
                </Layout>
            </Layout>
        )
    }
}

export default connect(({ global }: any) => {
    return {
        isFullScreening: global.isFullScreening
    }
})(CustomLayout)
