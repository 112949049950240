import React from 'react'
import { Layout } from 'antd'
import { connect } from 'dva'
import Menu from './menu'
import ScrollArea from 'react-scrollbar'
import {
    collapsedWidth,
    unCollapsedWidth
} from '@src/config'

const { Sider } = Layout

@connect(({ global }) => {
    const { collapsed, siderWidth } = global
    return {
        collapsed,
        siderWidth
    }
})
class AsideMenu extends React.Component {
    onCollapse = (collapsed) => {
        this.props.dispatch({
            type: 'global/setGlobalState',
            payload: {
                collapsed,
                siderWidth: collapsed ? collapsedWidth : unCollapsedWidth
            }
        })
    }

    render() {
        const { collapsed, siderWidth } = this.props
        // console.log('AsideMenu render', collapsed)
        return (
            <div className='navs' style={{ width: siderWidth, height: 'calc(100vh - 46px)' }}>
                <ScrollArea
                    minScrollSize={100}
                    smoothScrolling
                    style={{
                        // borderRight: '1px solid #efefef',
                        position: 'fixed',
                        left: 0,
                        height: 'calc(100vh - 46px)',
                        background: '#fff',
                        zIndex: 999
                    }}
                >
                    <Sider
                        className='left-nav'
                        collapsible 
                        collapsed={collapsed}
                        onCollapse={this.onCollapse}
                        width={unCollapsedWidth}
                        collapsedWidth={collapsedWidth}
                        theme={'light'}
                    >
                        <Menu />
                    </Sider>
                </ScrollArea>
            </div>
        )
    }
}

export default AsideMenu