import React from 'react'
import { Popover, Badge, Icon, Button } from 'antd'
import { connect } from 'dva'
import rApi from '@src/http'
import { AuthDef as MessagePage } from '@src/pages/message/index.module'
import { HandsomeSpin } from '@src/components/handsome'
import { formatTime } from '@src/libs/logic'

@connect()
class Message extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: false,
            list: [],
            visible: false
        }
    }

    shouldComponentUpdate (nextProps, nextState) {
        // console.log('shouldComponentUpdate', nextProps, this.props)
        const { visible, loading } = this.state
        const shouldUpdate = (visible !== nextState.visible || loading !== nextState.loading)
        return shouldUpdate
    }

    toMessage = () => {
        this.props.dispatch({
            type: 'global/menuClick',
            payload: { item: MessagePage.main }
        })
        this.setState({ visible: false })
    }

    getContent () {
        const { list, loading } = this.state
        return (
            <div className='pop_con'>
                <div className="mask" onClick={() => {
                    this.setState({ visible: false })
                }}></div>
                <div className="header">
                    <span className='title'>消息中心</span>
                    <Icon
                        type='close'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.setState({ visible: false })
                        }}
                    />
                </div>
                <div className="con">
                    <HandsomeSpin loading={loading}>
                        {
                            list && list.length ? list.map(item => (
                                <dl key={item.month}>
                                    <dt>{ item.month }</dt>
                                    {
                                        item.children && item.children.length ? item.children.map(c => (
                                            <React.Fragment key={c.index}>
                                                <dd className='title'>
                                                    <span>{ c.messageType }</span>
                                                    <time>{ c.createTimeText }</time>
                                                </dd>
                                                <dd className="desc">
                                                    { c.messageContent }
                                                </dd>
                                            </React.Fragment>
                                        )) : null
                                    }
                                </dl>
                            )) : null
                        }
                    </HandsomeSpin>
                </div>
                <div className="footer">
                    <Button
                        block
                        onClick={this.toMessage}
                    >
                        查看更多
                    </Button>
                </div>
            </div>
        )
    }

    onVisibleChange = (visible) => {
        if (visible) {
            this.getMessageList()
        }
    }

    getMessageList = async () => {
        try {
            this.setState({ loading: true })
            const res = await rApi[MessagePage.main.serviceName]({
                limit: 10,
                offset: 0
            })
            const list = this.deal(res.records || [])
            // console.log('list', list)
            this.setState({ list, loading: false })
        } catch (error) {
            console.log(error)
            this.setState({ list: [], loading: false })
        }
    }

    deal (arr) {
        arr = arr.map(item => ({ ...item, month: formatTime(item.createTime, 'YYYY-MM'), createTimeText: formatTime(item.createTime) }))
        const obj = {}
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            if (item.month) {
                if (!obj[item.month]) {
                    obj[item.month] = [item]
                } else {
                    obj[item.month].push(item)
                }
            }
        }
        const list = []
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const array = obj[key]
                list.push({
                    month: key,
                    children: array.map((item, index) => ({ ...item, index }))
                })
            }
        }
        return list
    }

    render() {
        // console.log('Message', this.state, this.props)
        const content = this.getContent()
        const { visible } = this.state
        return (
            <Popover
                overlayClassName='message_pop'
                placement="bottomRight"
                content={content}
                // trigger="click"
                arrowPointAtCenter
                getPopupContainer={node => node.parentNode.parentNode.parentNode}
                onVisibleChange={this.onVisibleChange}
                visible={visible}
            >
                <Badge count={5} dot={true} offset={[-12, 0]}>
                    <Icon
                        type='bell' 
                        title='通知'
                        className='header_msg'
                        onClick={() => {
                            this.setState({ visible: true })
                        }}
                    />
                </Badge>
            </Popover>
        )
    }
}
 
export default Message