import React from 'react'
import { Layout } from 'antd'
import { connect } from 'dva'
import { isFullscreen } from '@src/libs/util'
import {
    compareObjKeys
} from '@src/libs/logic'
import { IPower } from '@src/models/global'
import MyHeader from './header'
import MyContent from './content'

export interface IProps {
    moduleRefresh: boolean;
    dispatch: any;
    isFullScreening?: boolean;
    tabsList: IPower[];
    activeTab?: any;
    location: any;
}

class CustomLayout extends React.Component<IProps, any> {

    public shouldComponentUpdate (nextProps: any) {
        const keys = ['activeTab', 'moduleRefresh']
        return compareObjKeys(this.props, nextProps, keys)
    }

    public componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    public handleResize = () => {
        const { isFullScreening, dispatch } = this.props
        if (!isFullscreen() && isFullScreening) { // 全屏情况下按ESC键触发，更新isFullScreening状态
            dispatch({
                type: 'global/exitFullscreen'
            })
        }
        dispatch({
            type: 'global/setGlobalState',
            payload: {
                conHeight: window.innerHeight - 50
            }
        })
    }

    public render() {
        // console.log('CustomLayout render', this.props)
        return (
            <Layout className='custom_layout'>
                <MyHeader />
                
                <Layout
                    style={{ marginTop: 50 }}
                >
                    <MyContent />
                </Layout>
            </Layout>
        )
    }
}
 
export default connect(({ global }: any) => {
    return {
        ...global
    }
})(CustomLayout)