export const localRead = function (key, isJson = false) {
    // let ret = localStorage.getItem(key)
    try {
        const customerPlatform = JSON.parse(localStorage.getItem('customerPlatform')) || {}
        // if (ret) {
        //     return isJson ? JSON.parse(ret) : ret
        // } else {
        //     return ret
        // }
        return customerPlatform[key]
    } catch (e) {
        console.error('localRead', e)
        return null
    }
}

export const localSet = function (key, val) {
    try {
        const customerPlatform = JSON.parse(localStorage.getItem('customerPlatform')) || {}
        if (!!val && typeof val === 'object') {
            // localStorage.setItem(key, JSON.stringify(val))
            customerPlatform[key] = val
        } else {
            // localStorage.setItem(key, val)
            customerPlatform[key] = val
        }
        localStorage.setItem('customerPlatform', JSON.stringify(customerPlatform))
    } catch (e) {
        console.error('localSet', e)
    }
}

export const localRemove = function (key) {
    try {
        const customerPlatform = JSON.parse(localStorage.getItem('customerPlatform')) || {}
        delete customerPlatform[key]
        localStorage.setItem('customerPlatform', JSON.stringify(customerPlatform))
        return true
    } catch (e) {
        return false
    }
    // return localStorage.removeItem(key)
}

export const isArray = (o) => {
    if (typeof o !== 'object') {
        return false
    }
    return Object.prototype.toString.call(o) === '[object Array]';
}

export const cloneObject = function(obj) { // 对象数组的深拷贝
    let str
    if (typeof obj !== 'object') {
        return obj
    }
    let newobj = obj.constructor === Array ? [] : {}
    if (typeof obj !== 'object') {
        return
    } else if (window.JSON) {
        try {
            str = JSON.stringify(obj) // 系列化对象
            newobj = JSON.parse(str) // 还原
        } catch (e) {
            //console.log('cloneObject', obj)
            if (isArray(obj)) {
                return [...obj]
            } else {
                return {...obj, render: obj.render}
            }
        }
        
    } else {
        for (let i in obj) {
            newobj[i] = typeof obj[i] === 'object' ? cloneObject(obj[i]) : obj[i]
        }
    }
    // console.log('cloneObject newobj', newobj)
    return newobj
}

export const toJson = function(val) {
    try {
        const json = typeof val === 'string' ? JSON.parse(val) :  typeof val === 'object' ? val : {}
        return json
    } catch (error) {
        return {}
    }
}

export const isFullscreen = function () {
    return document.fullscreenElement ||
                   document.msFullscreenElement  ||
                   document.mozFullScreenElement ||
                   document.webkitFullscreenElement || false
}

export const sleep = timeoutMs => new Promise(resolve => setTimeout(resolve, timeoutMs))

export const isNumber = (value) => {
    return (typeof value === 'number' && !isNaN(value))
}
