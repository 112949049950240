import React, { Component } from 'react'
import { connect } from 'dva'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Icon } from 'antd'
import { Menu, Item, Separator, MenuProvider } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.min.css'
import { homeId } from '@src/config'

const cannotClosed = [
    '/home'
]

const AwesomeMenu = [
    {
        type: 'item',
        name: '关闭这个标签页',
        icon: 'close',
        value: 'close'
    },
    {
        type: 'item',
        name: '刷新这个标签页',
        icon: 'sync',
        value: 'refresh'
    },
    // {
    //     type: 'item',
    //     name: '全屏这个标签页',
    //     icon: 'fullscreen',
    //     value: 'fullscreen'
    // },
    {
        type: 'Separator'
    },
    {
        type: 'item',
        name: '关闭其他标签页',
        icon: 'arrows-alt',
        value: 'closeOther'
    },
    {
        type: 'item',
        name: '关闭所有标签页',
        icon: 'swap',
        value: 'closeAll'
    },
    {
        type: 'Separator'
    },
    {
        type: 'item',
        name: '关闭右侧标签页',
        icon: 'arrow-right',
        value: 'closeRight'
    },
    {
        type: 'item',
        name: '关闭左侧标签页',
        icon: 'arrow-left',
        value: 'closeLeft'
    }
]

const MyAwesomeMenu = ({ itemClick }) => {
    return (
        <Menu id='default_awesome_menu' style={{ zIndex: 1009 }}>
            {
                AwesomeMenu.map((item, index) => {
                    if (item.type === 'Separator') return <Separator key={index} />
                    return (
                        <Item data={{ value: item.value }} key={index} onClick={itemClick}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div><Icon type={item.icon} /></div>
                                <div><span className='right-click-menu-text'>{item.name}</span></div>
                            </div>
                        </Item>
                    )
                })
            }
        </Menu>
    )
}

const HomeAwesomeMenu = ({ itemClick }) => {
    return (
        <Menu id='home_awesome_menu' style={{ zIndex: 1009 }}>
            {
                AwesomeMenu.filter(item => item.name !== '关闭这个标签页').map((item, index) => {
                    if (item.type === 'Separator') return <Separator key={index} />
                    return (
                        <Item data={{ value: item.value }} key={index} onClick={itemClick}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div><Icon type={item.icon} /></div>
                                <div><span className='right-click-menu-text'>{item.name}</span></div>
                            </div>
                        </Item>
                    )
                })
            }
        </Menu>
    )
}

class SItem extends React.Component {
    render() {
        const { tab, tabClick, tabClose, tabIndex, activeTab } = this.props
        const canClose = tab && !cannotClosed.some(id => id === tab.id)
        // const zIndex = (activeTab && activeTab.id === tab.id) ? 9999 : (len - tabIndex)
        // console.log('SortableItem', tabIndex, len, zIndex)
        return (
            <MenuProvider
                id={tab.id !== homeId ? 'default_awesome_menu' : 'home_awesome_menu'}
                className={'global_header_tab_item' + ((activeTab && activeTab.id === tab.id) ? ' active' : '') + (!canClose ? ' index' : '')}
                // style={{ zIndex }}
                data={{ tab, tabIndex }}
                title={tab.name}
            >
                <div
                    className="trapezoid"
                    onClick={() => {
                        tabClick(tab, tabIndex)
                    }}
                ></div>
                <div className='flex' style={{ width: '100%', maxHeight: '100%', alignItems: 'center', overflow: 'hidden' }}>
                    <div
                        className='title global_text_overflow'
                        style={{ flex: 1, textAlign: 'center' }}
                        onClick={() => {
                            tabClick(tab, tabIndex)
                        }}
                    >
                        {tab.name}
                    </div>
                    <div style={{ zIndex: 10, width: 20, height: 20 }}>
                        {
                            canClose && (
                                <Icon
                                    className='close'
                                    type="close"
                                    style={{
                                        padding: 10
                                    }}
                                    onClick={e => {
                                        tabClose(tab, tabIndex)
                                        e.stopPropagation()
                                    }}
                                />
                            )
                        }
                    </div>
                </div>

            </MenuProvider>
        )
    }
}


const SortableItem = SortableElement(SItem)

const SortableList = SortableContainer(({ list, tabClick, tabClose, activeTab, left }) => {
    return (
        <div className='global_tab_list' style={{ left }}>
            {
                list.map((tab, index) => (
                    <SortableItem
                        key={`item-${tab.id}`}
                        activeTab={activeTab}
                        tab={tab}
                        len={list.length}
                        index={index} // 用于拖动显示效果
                        tabIndex={index}
                        tabClick={tabClick}
                        tabClose={tabClose}
                    />
                ))
            }
        </div>
    );
});

@connect(({ global }) => {
    return {
        ...global
    }
})
class MyTabs extends Component {
    scrollRef = null
    tabsWidth = 140
    tabsML = -12

    constructor(props) {
        super(props)
        this.state = {
            prevShow: false,
            nextShow: false,
            tabsLeft: 0,
            activeTab: props.activeTab
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { activeTab } = this.props
        this.setBtnStatus(nextProps, nextState)
        if (activeTab !== nextProps.activeTab) {
            this.setLeft(nextProps, nextState)
        }
        return true
    }

    shouldCancelStart = (e) => {
        // console.log('shouldCancelStart', e.target.title)
        if (e.target.title === '首页') {
            return true
        } else {
            return false
        }
    }

    onSortEnd = ({ oldIndex, newIndex, collection, isKeySorting }) => {
        // console.log('onSortEnd', oldIndex, newIndex)
        if (newIndex === 0) return
        const { tabsList, dispatch } = this.props
        const newTabsList = [...tabsList]
        const dragItem = newTabsList.splice(oldIndex, 1)[0]
        newTabsList.splice(newIndex, 0, dragItem)
        dispatch({
            type: 'global/updateTabsList',
            payload: newTabsList
        })
    }

    selectMenu = async (menu, tabIndex) => {
        const { dispatch } = this.props
        await dispatch({
            type: 'global/menuClick',
            payload: {
                item: menu
            }
        })
    }

    closeMenu = async (tab, tabIndex) => {
        const { dispatch, activeTab } = this.props
        if (activeTab.id === tab.id) {
            await dispatch({
                type: 'global/closeTheTab',
                payload: { tab, tabIndex }
            })
        } else {
            dispatch({
                type: 'global/closeOtherOneTab',
                payload: { tab, tabIndex }
            })
        }
    }

    refresh = async (tab, tabIndex) => {
        const { activeTab, dispatch } = this.props
        if (activeTab.id === tab.id) {
            await dispatch({ type: 'global/setGlobalState', payload: { moduleRefresh: true } })
            dispatch({ type: 'global/setGlobalState', payload: { moduleRefresh: false } })
        }
    }

    rightClickWindowEvent = (e) => {
        // console.log('rightClickWindowEvent', e)
        const { dispatch, activeTab } = this.props
        const { value, tab, tabIndex } = e.props
        switch (value) {
            case 'close':
                this.closeMenu(tab, tabIndex)
                break
            case 'closeOther':
            case 'closeAll':
            case 'closeLeft':
            case 'closeRight':
                dispatch({
                    type: 'global/closeTab',
                    payload: { type: value, tab, tabIndex }
                })
                break
            case 'refresh':
                this.refresh(tab, tabIndex)
                break
            case 'fullscreen':
                if (activeTab.id === tab.id) {
                    dispatch({
                        type: 'global/fullscreen'
                    })
                }
                break

            default:
                break
        }
    }

    onResize = () => {
        this.setBtnStatus()
        this.setLeft()
    }

    getMidConWidth = () => {
        if (this.scrollRef) {
            return this.scrollRef.offsetWidth
        } else {
            return 0
        }
    }

    getListWidth = (tabsList) => {
        tabsList = tabsList || this.props.tabsList
        return tabsList.reduce((rt) => rt += (this.tabsWidth + this.tabsML), 0) + 12
    }

    setBtnStatus = (props, state) => {
        const tabsList = props ? props.tabsList : this.props.tabsList
        const tabsLeft = state ? state.tabsLeft : this.state.tabsLeft
        // console.log('setBtnStatus', tabsLeft)
        const conWidth = this.getMidConWidth()
        const listWidth = tabsList.reduce((rt) => rt += (this.tabsWidth + this.tabsML), 0) + Math.abs(this.tabsML)
        let prevShow = false
        let nextShow = false
        if (!!conWidth && listWidth >= conWidth) {
            prevShow = tabsLeft < 0
            nextShow = tabsLeft !== conWidth - listWidth
        } else {
            prevShow = false
            nextShow = false
        }
        if (prevShow !== this.state.prevShow || nextShow !== this.state.nextShow) {
            this.setState({ prevShow, nextShow })
        }
    }

    setLeft = (props, state) => {
        const activeTab = props ? props.activeTab : this.props.activeTab
        const tabsList = props ? props.tabsList : this.props.tabsList
        const tabsLeft = state ? state.tabsLeft : this.state.tabsLeft
        const conWidth = this.getMidConWidth()
        const listWidth = tabsList.reduce((rt) => rt += (this.tabsWidth + this.tabsML), 0) + Math.abs(this.tabsML)
        const curIndex = tabsList.findIndex(tab => tab.id === activeTab.id) || 0
        const curLeft = (this.tabsWidth + (curIndex > 0 ? this.tabsML : 0)) * curIndex
        const curRight = curLeft + this.tabsWidth
        if (!!conWidth && listWidth >= conWidth) {
            if (curLeft < Math.abs(tabsLeft)) {
                this.setState({ tabsLeft: -curLeft })
            } else if (curRight + tabsLeft > conWidth) {
                this.setState({ tabsLeft: -(curRight - conWidth) })
            }
        }
    }

    moveTabs = (type) => {
        const { tabsLeft } = this.state
        const { tabsList, activeTab } = this.props
        const conWidth = this.getMidConWidth()
        const listWidth = tabsList.reduce((rt) => rt += (this.tabsWidth + this.tabsML), 0) + Math.abs(this.tabsML)
        const curIndex = tabsList.findIndex(tab => tab.id === activeTab.id) || 0
        const curLeft = (this.tabsWidth + (curIndex > 0 ? this.tabsML : 0)) * curIndex
        const curRight = curLeft + this.tabsWidth
        if (type === 'prev') {
            this.setState({ tabsLeft: 0 }, () => {
                setTimeout(() => {
                    const ret = tabsLeft + (conWidth - (curRight + tabsLeft))
                    this.setState({ tabsLeft: ret < 0 ? ret : 0 })
                }, 200)
            })
        } else {
            this.setState({ tabsLeft: conWidth - listWidth }, () => {
                setTimeout(() => {
                    const ret = -curLeft
                    this.setState({ tabsLeft: ret > conWidth - listWidth ? ret : conWidth - listWidth })
                }, 200)
            })
        }
    }

    render() {
        const { prevShow, nextShow, tabsLeft } = this.state
        const { tabsList, activeTab } = this.props
        // console.log('render')
        return (
            <React.Fragment>
                <MyAwesomeMenu
                    itemClick={this.rightClickWindowEvent}
                />
                <HomeAwesomeMenu
                    itemClick={this.rightClickWindowEvent}
                />
                <div
                    className='mid_con'
                >
                    <div
                        className={`prev_btn ${prevShow ? 'show' : 'hide'}`}
                        onClick={() => this.moveTabs('prev')}
                    >
                        {'<'}
                    </div>
                    <div
                        className='mid_list'
                        ref={vm => this.scrollRef = vm}
                    >
                        <SortableList
                            lockToContainerEdges
                            hideSortableGhost
                            axis={'x'}
                            lockAxis={'x'}
                            lockOffset={'0%'}
                            distance={2}
                            list={tabsList}
                            activeTab={activeTab}
                            onSortEnd={this.onSortEnd}
                            shouldCancelStart={this.shouldCancelStart}
                            tabClick={this.selectMenu}
                            tabClose={this.closeMenu}
                            helperClass='global_header_sort_drag_tab_item'
                            left={tabsLeft}
                        />
                    </div>
                    <div
                        className={`next_btn ${nextShow ? 'show' : 'hide'}`}
                        onClick={() => this.moveTabs('next')}
                    >
                        {'>'}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MyTabs
