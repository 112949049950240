import React from 'react'
import { Layout, Menu, Icon, Dropdown } from 'antd'
import { connect } from 'dva'
import logo from '@src/assets/image/logo-top.png'
import logoMin from '@src/assets/image/logo@32.png'
import Tabs from './tabs'
import Message from './message'
import { compareObjKeys } from '@src/libs/logic'
import { path as userInfoPath } from '@src/pages/user_info/index.module'

const { Header } = Layout

@connect(({ global: {
    userName,
    collapsed,
    siderWidth,
    companyInfo,
    LOGIN_TYPE
} }) => {
    return {
        userName,
        collapsed,
        siderWidth,
        LOGIN_TYPE,
        companyInfo
    }
})
class MyHeader extends React.Component {

    shouldComponentUpdate (nextProps) {
        const flag = compareObjKeys(this.props, nextProps, ['collapsed'])
        // console.log('shouldComponentUpdate', flag, this.props)
        return flag
    }

    handleClick = (e) => {
        const { dispatch } = this.props
        if (e.key === 'logout') {
            dispatch({
                type: 'global/logout'
            })
        } else {
            dispatch({
                type: 'global/menuClick',
                payload: {
                    item: {
                        id: userInfoPath,
                        path: userInfoPath
                    }
                }
            })
        }
       
    }


    render() {
        const {
            userName,
            collapsed,
            siderWidth,
            LOGIN_TYPE,
            companyInfo
        } = this.props
        const logoStyle = {
            width: siderWidth,
            fontSize: 0,
            // textAlign: 'center',
            paddingLeft: 15,
            transition: 'width 200ms ease 0s',
            flexShrink: 0
        }
        // console.log('header render')

        return (
            <Header
                className="layout_header"
            >
                <div style={logoStyle} className="logo">
                    <img style={{maxHeight: '80%'}} src={!collapsed ? companyInfo.logo || logo : companyInfo.logo || logoMin} alt={'Logo'} />
                </div>
                {
                    LOGIN_TYPE === 1 && (
                        <Tabs />
                    )
                }
                <div className='right_con'>
                    {
                        LOGIN_TYPE === 1 && (
                            <Message />
                        )
                    }
                    <Dropdown trigger={['click']} overlay={
                        <div style={{ background: 'white' }}>
                            <Menu selectable={false} onClick={this.handleClick}>
                                {/* <Menu.Item key='user'>
                                    <Icon type="user" />
                                    {'客户信息'}
                                </Menu.Item> */}
                                <Menu.Item key='logout'>
                                    <Icon type="logout" />
                                    {'登出'}
                                </Menu.Item>
                            </Menu>
                        </div>
                    }>
                        <div style={{ 
                            cursor: 'pointer',
                            color: 'white', 
                            borderLeft: '1px solid #656565', 
                            paddingLeft: 18,
                            minWidth: 100, 
                            height: 30, 
                            lineHeight: '30px', 
                            textAlign: 'center' }
                        }>
                            {userName}&nbsp;
                            <Icon type="down" />
                        </div>
                    </Dropdown>
                </div>
            </Header>
        )
    }
}
 
export default MyHeader