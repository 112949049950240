import React from 'react'
import { Menu, Icon } from 'antd'
import { connect } from 'dva'
import {
    sortMenus
} from '@src/libs/logic'

const { SubMenu } = Menu

const menuIconTypes = {
    '首页': 'home',
    '基础配置': 'setting',
    '权限配置': 'warning',
    '运作监控': 'fund'
}
const iconStyle = { fontSize: '14px' }

function getType (menu) {
    const c = menu.children || []
    if (menu.type === 'page') {
        return 1
    } else if (c.length > 0 && menu.type !== 'page_hide') {
        return 2
    } else {
        return 0
    }
}

function getIcon (key) {
    const type = menuIconTypes[key]
    return !!type ? <Icon style={iconStyle} type={type} /> : null
}

@connect(({ global }) => {
    const { activeTab, onlineTree } = global
    return {
        activeTab,
        onlineTree
    }
})
class AsideMenu extends React.Component {
    onMenuItemClick = ({ item: { props: { data } }, key, keyPath }) => {
        const { dispatch } = this.props
        dispatch({
            type: 'global/menuClick',
            payload: {
                item: data
            }
        })
    }

    itemRender (menu, deep = 1) {
        if (!menu) return null
        const { name, code, id, path, children } = menu
        // console.log('itemRender', menu, deep)
        const type = getType(menu)
        const key = code || id
        const icon = getIcon(name)
        sortMenus(children)

        switch (type) {
            case 1:
                return (
                    <Menu.Item
                        path={path}
                        data={menu}
                        style={{ marginBottom: 4, fontSize: 14 }}
                        key={key}
                    >
                        <span to={path}>
                            { icon }
                            <span title={name}>{name}</span>
                        </span>
                    </Menu.Item>
                )

            case 2:
                deep++
                return (
                    <SubMenu
                        key={key}
                        title={
                            <span>
                                { icon }
                                <span style={{ fontSize: 14 }} title={name}>
                                    {name}
                                </span>
                            </span>
                        }
                    >
                        { children.map(c => this.itemRender(c, deep)) }
                    </SubMenu>
                )

            default:
                return null
        }
    }

    render() {
        const { activeTab, onlineTree } = this.props
        // console.log('menu render', this.props)
        const selectedKeys = activeTab && activeTab.id ? [activeTab.id] : []
        return (
            <Menu
                theme="light"
                // selectable={false}
                selectedKeys={selectedKeys}
                mode={'inline'}
                style={{ borderRight: 0 }}
                onClick={this.onMenuItemClick}
            >
                {
                    onlineTree && onlineTree.length ? sortMenus(onlineTree).map(item => this.itemRender(item)) : null    
                }
            </Menu>
        )
    }
}

export default AsideMenu