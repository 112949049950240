// 手机号验证
const phoneRule = {
    message: '请输入正确的手机格式',
    pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
    test: function (phone) {
        return /^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)
    }
}

export {
    phoneRule
}